<template>
  <div class="mt-2">
    <v-progress-linear
      indeterminate
      color="cyan"
      v-if="apirequestLoading"
    ></v-progress-linear>
    <template v-else>
      <v-list color="#f0f0f0" min-height="26px" dense class="pa-0 mb-1">
        <v-list-item class="pa-0 px-1">
          <v-list-item-icon class="mr-0">
              <span>#</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('message.mixed.checklistName') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ $t('message.mixed.noOfDocuments') }}</v-list-item-title>
            </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-expansion-panels multiple focusable>
        <v-expansion-panel v-for="(checklist, i) in listOfChecklistDocuments" :key="i">
          <v-expansion-panel-header style="min-height:36px;" class="pa-1">
            <span class="text-truncate">
              <strong style="float:left: width:content-width">{{i + 1}}.</strong>
              <!-- <v-divider vertical class="pa-0 mx-1"></v-divider> -->
              {{ checklist.checklist_name }}
            </span>
            <template v-slot:actions>
              <v-btn small rounded color="primary">
                {{ checklist.documents ? checklist.documents.length : 0 }} <v-icon right color="white">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="custom_content_wrap">
            <v-row class="mx-0">
              <v-col cols="12" class="pa-0">
                <v-simple-table dense width="100%" class="dense_table bordered--table">
                  <thead>
                    <tr>
                      <th v-for="(documentHeader, index) in documentHeaders" :key="index">{{index === 0 ? documentHeader.text :$t(documentHeader.text)}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(document, i) in checklist.documents" :key="i">
                      <td>{{i + 1}}</td>
                      <td>{{document.file_original_name}}</td>
                      <td>
                        <v-btn x-small fab color="primary" text>
                          <v-icon size="24" @click="downloadDocument(document._id)">mdi-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import { checklistApi, hostAppApi } from '../../plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      expanded: [],
      singleExpand: false,
      listOfChecklistDocuments: [],
      headers: [{
        text: '',
        value: 'data-table-expand',
        width: '10%'
      }, {
        text: 'message.mixed.checklistName',
        value: 'checklist_name',
        width: '90%'
      }],
      documentHeaders: [{
        text: '#',
        value: 'slNo'
      }, {
        text: 'message.mixed.documentTitle',
        value: 'title'
      }, {
        text: 'message.common.download',
        value: 'file_original_name'
      }],
      apirequestLoading: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  created () {
    this.getAllChecklistIds()
  },
  methods: {
    getAllChecklistIds () {
      this.apirequestLoading = true
      hostAppApi.get(`${this.getHostRefApi}get-checklist-ids?user_id=${this.userId}`)
        .then((response) => {
          if (response.data.ids && response.data.ids.length) this.getAllChecklistDocuments(response.data.ids)
          else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.mixed.nochecklists' })
        })
    },
    getAllChecklistDocuments (ids) {
      checklistApi.post('documents/get_documents_of_all_checklists', { ids }).then((response) => {
        this.listOfChecklistDocuments = response.data
      }).finally(() => { this.apirequestLoading = false })
    },
    downloadDocument (id) {
      this.apirequestLoading = true
      checklistApi.get(`documents/download/${id}`).then(response => {
        const filename = response.data
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${filename}`, '_blank')
      }).finally(() => { this.apirequestLoading = false })
    }
  }
}
</script>

<style>
.custom-icon-size {
  font-size: 24px !important;
}
.custom_content_wrap > .v-expansion-panel-content__wrap {
  margin: 0;
  padding: 0.4rem;
}
</style>
